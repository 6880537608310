<template>
  <div>
    <v-row
      justify="center"
      class="mt-5"
    >
      <v-dialog
        v-model="dialog"
        content-class="white-bg "
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            dark
            v-on="on"
          >
            Create Article
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <v-icon>mdi-plus</v-icon>
            <span
              v-if="article.id"
              class="headline"
            >Update Article</span>
            <span
              v-else
              class="headline"
            >New Article</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="article.title"
                    label="Title*"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="article.image"
                    label="Image"
                  />
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="article.menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="article.dateFormatted"
                        label="Publish Date"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="article.dateFormatted"
                      @change="saveDate"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="article.lang"
                    :items="['de', 'en', 'fr']"
                    label="Lang*"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="article.body"
                    label="Text*"
                    persistent-hint
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="article.twitter"
                    label="Twitter"
                    prepend-icon="mdi-twitter"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="article.facebook"
                    label="Facebook"
                    prepend-icon="mdi-facebook"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="article.linkedin"
                    label="LinkedIn"
                    prepend-icon="mdi-linkedin"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="article.youtube"
                    label="YouTube"
                    prepend-icon="mdi-youtube"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="article.document"
                    label="Document"
                    prepend-icon="mdi-file"
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-text v-show="hasErrors">
            <v-alert
              outlined
              color="red"
              dense
            >
              <div class="title">
                Alert
              </div>

              <ul>
                <li v-show="!validation.title">
                  title cannot be empty.
                </li>
                <li v-show="!validation.date">
                  date cannot be empty.
                </li>
                <li v-show="!validation.body">
                  body cannot be empty.
                </li>
                <li v-show="!validation.lang">
                  lang cannot be empty.
                </li>
              </ul>
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="cancel"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveArticle"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Articles',
    data () {
      return {
        hasErrors: false,
        dialog: false,
        article: {
          title: null,
          body: null,
          date: null,
          dateFormatted: null,
          lang: null,
          document: null,
          image: null,
          menu: false,
          linkedin: null,
          twitter: null,
          facebook: null,
          youtube: null,
        },
        fromDateMenu: false,
        fromDateVal: null,

        minDate: null,
        maxDate: null,
        articlesRef: null,
      }
    },
    computed: {
      validation: function () {
        return {
          title: !!this.article.title,
          body: !!this.article.body,
          date: true,
          lang: !!this.article.lang,
        }
      },
      isValid: function () {
        const validation = this.validation
        return Object.keys(validation).every(function (key) {
          return validation[key]
        })
      },
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },

    mounted () {

    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
    },
    methods: {
      cancel () {
        this.article = {}
        this.dialog = false
      },
      saveDate (date) {
        this.$refs.menu.save(date)
      },
      copyArticle (article = null) {
        this.article = JSON.parse(JSON.stringify(article))
        delete this.article.id
        this.article.dateFormatted = moment(article.date.toDate()).format('YYYY-MM-DD')
        this.dialog = true
      },
      editArticle (article = null) {
        this.article = JSON.parse(JSON.stringify(article))
        this.article.dateFormatted = moment(article.date.toDate()).format('YYYY-MM-DD')
        this.dialog = true
      },
      saveArticle () {
        const articlesRef = this.$firebaseDatabase.collection('articles')
        if (this.isValid) {
          this.article.date = new Date(this.article.dateFormatted)
          if (this.article.id) {
            const doc = articlesRef.doc(this.article.id)
            delete this.article.id
            doc.set(this.article)
          } else {
            articlesRef.add(this.article)
          }
          this.article = {}
          this.dialog = false
          this.$parent.fetchArticles()
        } else {
          this.hasErrors = true
        }
      },
    },
  }

</script>
